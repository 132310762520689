import {call, put, takeLatest, Effect, select, getContext} from "redux-saga/effects";
import { setToken, registerSuccess, registerFailure, forgotPasswordSuccess, forgotPasswordFailure, removeToken, loginSuccess, loginFailure, getProfileSuccess, getProfileFailure, changeOTPScreenView, verifyUserFailure, verifyUserSuccess, setOTPValidationMessage } from './handlers';
import { registerRequest, loginRequest, verifyUserRequest, logout, getProfileRequest, forgotPasswordRequest, removeProfile, 
	setSignInValidationMessage, setSignUpValidationMessage, setForgotPwdValidationMessage, setForgotMode } from "./handlers";
import { setCloseSidebarDirection, setLoading, setNextPath } from '../app/handlers';
import { getCartRequest, saveCartRequest } from "../cart/handlers";
import { getProductsRequest } from "../product/handlers";
import { selectBillingCard, selectShippingAddress, selectPromoCode, } from "../patient/handlers";
import * as httpAuth from "../../http/auth";
import http from "../../http";

import {getToken, getProfile, getProfilePageToOpen} from "../selectors";
import {ActionType} from "../types";

import MessageConst from "../../const/Message.const";

function* onRegister({payload}: ActionType): Generator<any, void, any> {
	const {isLoginMode, tokenId, ...profileData} = payload;

	try {
		let result:any;
		let profileInfo = {...profileData};
		let utmSource = profileInfo.utmSource;
		if(tokenId){
			console.log("google signup")
			result = yield call(httpAuth.googleSignup, {tokenId, utmSource});
		}else{
			result = yield call(httpAuth.register, {...profileData});
		}

		const {profile, token} = result.data;

		http.defaults.headers.Authorization = "Bearer " + token;
		yield put(setToken(token));
	 	yield put(registerSuccess(profile));
		yield put(changeOTPScreenView(true))
		yield put(setCloseSidebarDirection(null));

		if (profile?.authority === "PATIENT") {
			yield put(getCartRequest());
		}
		// if (isLoginMode) {
			
		// 	const profilePageToOpen = yield select(getProfilePageToOpen);
		// 	if (profilePageToOpen) {
		// 		yield put(setNextPath(profilePageToOpen));
		// 	} else {
		// 		yield put(setNextPath("/account"));
		// 	}
		// } else {
		// 	console.log("Not login mode");
		// }
	} catch (error:any) {
		console.log(error);
		const profileget  = error.response.data;
		if([409].includes(error.response.status)){
			yield put(setNextPath("/google-link"));
		}	
		
		yield put(setSignUpValidationMessage(error.response.data.detail || MessageConst.validation.registerError));
		yield put(removeToken());
		yield put(registerFailure(profileget));
	}
}

function* onVerifyUser({payload}: ActionType): Generator<any, void, any>{
	let result:any
	try {
		result = yield call(httpAuth.verifyUser, {...payload});
		console.log(result.data)
		yield put(verifyUserSuccess(result.data))
		yield put(changeOTPScreenView(false))
		const profilePageToOpen = yield select(getProfilePageToOpen);
			if (profilePageToOpen) {
				yield put(setNextPath(profilePageToOpen));
			} else {
				yield put(setNextPath("/account"));
			}
	} catch (error: any) {
		console.log("OTP Error", error)
		yield put(changeOTPScreenView(true))
		yield put(setOTPValidationMessage(error.response.data.detail || MessageConst.validation.registerError))
	}
}

function* onForgotPassword({payload}: ActionType) {
	const {email} = payload;

	try {
		yield call(httpAuth.forgotPassword, email);

		yield put(setForgotPwdValidationMessage(""));
		yield put(setForgotMode("EMAIL_SENT"));

		yield put(forgotPasswordSuccess());
	} catch (error:any) {
		yield put(setForgotPwdValidationMessage(error.response.data.message));
		yield put(forgotPasswordFailure());
	}
}

function* onLogin({payload}: ActionType): Generator<any, void, any> {
	const {email, password, callback, isLoginMode, tokenId,isLinkToGoogle,reCaptchaToken} = payload;

	try {
		let result;
		if(tokenId){
			result = yield call(httpAuth.googleLogin, {tokenId});
		}else if(isLinkToGoogle){
			result = yield call(httpAuth.googlelink,{email,password})
		}
		else{
			result = yield call(httpAuth.login, {email, password, reCaptchaToken});
		}		
		const {profile, token} = result.data;

		http.defaults.headers.Authorization = "Bearer " + token;

		if(!profile.isVerified){
			yield put(changeOTPScreenView(true))
		}
		yield put(loginSuccess(profile));
		yield put(setToken(token));
		yield put(setCloseSidebarDirection(null));

		
		if ( isLoginMode && profile.authority === "PATIENT") {
			const profilePageToOpen = yield select(getProfilePageToOpen);
			if(callback && profile.phone){
				yield put(setNextPath('/shipping'));
			} else if(callback){
					yield put(setNextPath("/account"));	
			} else if (profilePageToOpen) {
				yield put(setNextPath(profilePageToOpen));
			} else {
				yield put(setNextPath("/profile/account-info"));
			}
		}

		const cart = localStorage.getItem("cartProducts");

		if (cart && profile.authority === "PATIENT") {
			yield put(saveCartRequest(JSON.parse(cart)));
		}
		if(profile.authority === 'PATIENT'){
			yield put(getCartRequest());
		}
	} catch (error: any) {
		const axiosError = error as { response: { status:number,data: { message: string } } };
		// console.log(error.response.data.email);		
		if([409].includes(axiosError.response.status)){
			const profileget  = axiosError.response.data;
			yield put(loginFailure(profileget));
			yield put(setNextPath('/google-link'));
		}else if([400].includes(axiosError.response.status)){
			yield put(setSignInValidationMessage(axiosError.response.data.message || error.response.data.detail));
		}else{
			yield put(setSignInValidationMessage( MessageConst.validation.authError));
		}
		yield put(removeToken());
	}
}

function* onGetProfile({payload}: ActionType): Generator<any, void, any> {
	try {
		const token = yield select(getToken);

		if (token) {
			http.defaults.headers.Authorization = "Bearer " + token;

			const result = yield call(httpAuth.getProfile);
			const profile = result.data;

			yield put(getProfileSuccess(profile));
			
			if (payload?.mode === "forgot") {
				yield put(setNextPath("/profile/account-info"));
			}

			yield put(getProductsRequest());
		} else {
			yield put(getProductsRequest());
		}

		yield put(setLoading(false));
	} catch (error) {
		yield put(getProductsRequest());
		yield put(removeToken());
		yield put(setLoading(false));
		yield put(getProfileFailure());
	}
}

function* onLogout() {
	try {
		const {authority} = yield select(getProfile);

		if (authority === "DOCTOR" || authority === "PHARMACIST") {
			yield put(getProductsRequest());
		}
		yield put(setNextPath("/"));		
		yield put(removeProfile());
		yield put(removeToken());
		yield put(selectShippingAddress(null));
		yield put(selectBillingCard(null));
		yield put(selectPromoCode(null));
		
	} catch (error) {
		console.log(error);
	}
}

const authSagas: Effect[] = [
	takeLatest(getProfileRequest, onGetProfile),
	takeLatest(loginRequest, onLogin),
	takeLatest(registerRequest, onRegister),
	takeLatest(verifyUserRequest, onVerifyUser),
	takeLatest(forgotPasswordRequest, onForgotPassword),
	takeLatest(logout, onLogout)
];

export default authSagas;
