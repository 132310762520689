import React, {
	ChangeEvent,
	useRef,
	useState,
	KeyboardEvent,
	forwardRef,
	useEffect
} from "react";
import {  CodeResponse, useGoogleLogin } from "@react-oauth/google";
import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import MessageConst from "../../../const/Message.const";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../../../redux/auth/handlers";
import { clearNextPath } from "../../../redux/app/handlers";
import { Link, useNavigate, useLocation } from "react-router-dom";
import google from "../../../static/images/google.png";
import { clearSignUpValidationMessage } from "../../../redux/auth/handlers";
import ValidationService from "../../../service/Validation.service";
import { StateType } from "../../../redux/types";
import axios from "axios";
import { Modal } from "react-bootstrap";
import close from "../../../static/images/close.png";


interface SignupProps {
	doRegister: () => void;
}

const Signup = forwardRef((props: SignupProps) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const emailInput = useRef<HTMLInputElement>(null);
	const passwordInput = useRef<HTMLInputElement>(null);
    const [email, setEmail] = useState<string>("");
	const [capVal, setCapVal] = useState<string|null>("")
	const [captchaToken, setToken] = useState<string>('');
	const { executeRecaptcha } = useGoogleReCaptcha();
	const [password, setPassword] = useState<string>("");
	const authValidationMessage = useSelector((state: StateType) => state.auth.signUpValidationMessage);
	const otpValidationMessage = useSelector((state: StateType) => state.auth.otpValidationMessage)
	const userId = useSelector((state:StateType) => state.auth.profile?.id)
	const showOTPScreen = useSelector((state: StateType) => state.auth.otpShow)
	const [validationMessage, setValidationMessage] = useState<string>("");
	const [OTPvalidationMessage, setOTPValidationMessage] = useState<string>("");
	const [isAgreed, setIsAgreed] = useState<boolean>(false);
	const isLogin = useRef<boolean>(false);

	const [OTPshow, setotpShow] = useState<boolean>();

	const otpClose = () => {
		setotpShow(false)
		dispatch(authActions.changeOTPScreenView(false))
		dispatch(authActions.setOTPValidationMessage(""))
	};
	const otpShow = () => setotpShow(true);

	useEffect(() => {
		dispatch(authActions.setSignUpValidationMessage(""));
	}, [])

	useEffect(() => {
        if (!executeRecaptcha) {
            return;
        }
        const handleReCaptchaVerify = async () => {
            const token = await executeRecaptcha('register');
            setToken(token);
        };
        handleReCaptchaVerify();
    }, [executeRecaptcha]);

	useEffect(()=>{
		setValidationMessage(authValidationMessage);
	}, [authValidationMessage]);

	useEffect(()=>{
		setOTPValidationMessage(otpValidationMessage)
	},[otpValidationMessage])

	useEffect(()=>{
		setotpShow(showOTPScreen)
	},[showOTPScreen])

	const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			props.doRegister();
		}
	};
	const handleNewEmail = (e: ChangeEvent<HTMLInputElement>) => {
		const newEmail: string = e.target.value.trim();

		setEmail(newEmail);

		if (ValidationService.validateEmail(newEmail)) {
			setValidationMessage("");
		}
	};
	const handleNewPassword = (e: ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value);

		if (ValidationService.validatePassword(e.target.value)) {
			setValidationMessage("");
		}
	};
	const handleNavigation = (path: any) => {
		navigate(path);
	}
	const doRegister = async (): Promise<void> => {
		if (!ValidationService.validateEmail(email)) {
			if (emailInput.current) {
				emailInput.current.focus();
			}

			setValidationMessage(MessageConst.validation.emailError);

			return;
		}

		if (!ValidationService.validatePassword(password)) {
			if (passwordInput.current) {
				passwordInput.current.focus();
			}

			setValidationMessage(MessageConst.validation.passwordError);

			return;
		}
		if (!isAgreed) {
			setValidationMessage(MessageConst.validation.termsOfUseError);

			return;
		}
		let utmSource = window.sessionStorage.getItem("utm_source");
		dispatch(clearSignUpValidationMessage());
		dispatch(
			authActions.registerRequest({
				email,
				password,
				isAgreed,
				utmSource,
				"reCaptchaToken":captchaToken,
				isLoginMode: pathname === "/login"
			})
		)
	}
	const handleIsAgreed = (event: ChangeEvent<HTMLInputElement>) => {
		const newAgreedState: boolean = event.target.checked;

		if (!newAgreedState) {
			setValidationMessage(MessageConst.validation.termsOfUseError);
		} else {
			setValidationMessage("");
		}

		setIsAgreed(newAgreedState);
	};
	const onSuccessSignup = async (res: CodeResponse) => {
		let tokenId = res.code;
		let utmSource = window.sessionStorage.getItem("utm_source");
		if (isLogin.current == true) {
			dispatch(
				authActions.loginRequest({
					email,
					password,
					isLoginMode: pathname === "/login",
					tokenId
				})
			);
		} else {

			dispatch(
				authActions.registerRequest({
					email,
					password,
					isAgreed,
					utmSource,
					isLoginMode: pathname === "/login",
					tokenId
				})
			)
		}
		// res.disconnect();
	}
	const onFailureSignup = (res: any) => {
		console.log('[Login Failed] res:', res);
	}


	const signIn = useGoogleLogin({
		onSuccess: onSuccessSignup,
		onError: onFailureSignup,
		flow: 'auth-code'
	});

	const handleGoogleSignUp = () => {
		isLogin.current = false;
		signIn();
	}

	const handleGoogleSignIn = () => {
		isLogin.current = true;
		signIn();
	}

	const handleRecaptchaChange = (val: string|null) => {
		setCapVal(val)
		console.log("capture values", val)

	}



	const [otp, setOtp] = useState<string[]>(['', '', '', '', '', '']); // State to manage OTP values

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
		const { value } = e.target;
		
		
		if (value.length <= 1 && /^[0-9]*$/.test(value)) {
		  const newOtp = [...otp]; // Copy the current OTP state
		  newOtp[index] = value; // Update the digit at the specific index
	
		  // If the value is not empty, automatically move to the next input
		  if (value && index < otp.length - 1) {
			const nextInput = document.querySelectorAll<HTMLInputElement>('.otpInput')[index + 1];
			nextInput?.focus();
		  }
	
		  setOtp(newOtp); // Update the OTP state
		}
	  };
	  const handleBackspace = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
		if (e.key === 'Backspace' && otp[index] === '') {
		  // If the current OTP field is empty and Backspace is pressed, focus on the previous field
		  if (index > 0) {
			const prevInput = document.querySelectorAll<HTMLInputElement>('.otpInput')[index - 1];
			prevInput?.focus();
		  }
		} else if (e.key === 'Backspace') {
		  // If the current field is not empty, remove the value and update state
		  const newOtp = [...otp];
		  newOtp[index] = ''; // Clear the current OTP value
		  setOtp(newOtp); // Update the OTP state
		}
	  };
	
  
	const handleOtpFocus = (index: number) => {
	  const otpInputs = document.querySelectorAll<HTMLInputElement>('.otpInput');
	  otpInputs[index]?.focus();
	};

	const doVerifyOTP = async (): Promise<void> => {
	
			if(!ValidationService.validateOTP){

				setOTPValidationMessage(MessageConst.validation.otpRequired);
				return;
			}
			dispatch(clearSignUpValidationMessage());
			dispatch(authActions.verifyUserRequest({
				userId,
				code:otp.join("")
			}))
		
	}
	return (
		<>
			<div className="div-block-40">
				<h1 className="heading">Join Winston</h1>
				<p className="paragraph">Let’s start with a few details to set up your space</p>
				<div className="w-form">
					<form id="email-form" name="email-form" data-name="Email Form" method="get" className="form">
						<input
							type="email"
							className="text-field w-input"
							placeholder="Email address"
							id="name"
							ref={emailInput}
							value={email}
							onChange={handleNewEmail}
							onKeyDown={handleKeyPress}
						/>
						<input
							type="password"
							className="text-field-2 w-input"
							placeholder="Password"
							id="email"
							ref={passwordInput}
							value={password}
							onChange={handleNewPassword}
							onKeyDown={handleKeyPress}
						/>
						{/* {validationMessage && (
                  <div className="alert">{validationMessage}</div>
                 )} */}
						<label className="w-checkbox checkbox-field">
							<input
								type="checkbox"
								id="checkbox"
								name="checkbox"
								data-name="Checkbox"
								required className="w-checkbox-input"
								onChange={handleIsAgreed} checked={isAgreed}
							/>
							<span className="checkbox-label w-form-label">
								I agree to the
								<span>
									<Link to="/terms-of-use" target="_blank">
										{" "}
										Terms of Use
									</Link>{" "}
									and{" "}
									<Link to="/privacy-policy" target="_blank">
										Privacy Policy
									</Link>
								</span>
							</span></label>

						{/* validation column  */}
						<div className={validationMessage ? "w-form-fail-show" : "w-form-fail"}>
							<div dangerouslySetInnerHTML={{ __html: validationMessage }}></div>
						</div>

						<span onClick={doRegister} className="button-8 w-button"><strong>Create Account</strong></span>
						<div className="div-block-41"><img src={google} width="27" alt="" className="image-19" />
							<button type="button" onClick={handleGoogleSignUp} className="heading-30">Sign up with Google</button>
						</div>

						{/* <button type="button" onClick={otpShow}>OTP Modal</button> */}

					</form>

				</div>

			</div>
			{/* modal starts here  */}
			<Modal show={OTPshow} animation={false} className="otpModal" backdrop="static">
				 <div className="modal-content">
      			<div className="modal-header">
       			<h5 className="modal-title">OTP Verification</h5>
        		<button
					type="button"
					className="close"
					aria-label="Close"
					onClick={otpClose}
				>
          		<img src={close} width="25" alt="close" />
        	  </button>
      		</div>
      <div className="modal-body">
        <h3 className="optBodyHeading">Enter your verification code</h3>
        <p className="optBodyDescription">We will send you an One Time Password</p>
        <div className="otpFields">
          {otp.map((digit, index) => (
            <input
              key={index}
              className="otpInput"
              type="number"
              value={digit}
              maxLength={1} // Only one digit per input
			  onChange={(e) => handleInputChange(e, index)}
			  onKeyDown={(e) => handleBackspace(e, index)}
              onFocus={() => handleOtpFocus(index)}
            />
          ))}
        </div>
		  {/*validation message*/}
		<div className={OTPvalidationMessage ? "w-form-fail-show" : "w-form-fail"}>
			<div dangerouslySetInnerHTML={{ __html: OTPvalidationMessage }}></div>
		</div>
        {/* <div className="otpResend">
          <div>
            <p>Didn't get it?</p>
            <button className="resendBtn" type="button">Resend code</button>
          </div>
          <p className="otpTime">1:00</p>
        </div> */}
		 <div className="otpVerifyBtn-block">
		<button className="otpVerifyBtn" type="button" onClick={doVerifyOTP}>Verify OTP</button>
	</div>
        
      </div>

      {/* Invisible input field to type OTP */}
      <input
        type="text"
        value={otp.join('')}
		onChange={(e) => handleInputChange(e, 0)} 
        className="invisibleInput" // Invisible to the user
        autoFocus
      />
	 
    </div>
	
			</Modal>
			{/* modal ends here */}
		</>
	)
})
export default Signup;
